import React, { useState } from "react"
import Layout from "../layout"
import SEO from "../components/SEO"
import Modulram from "../components/Configuradores/inputs/modulram.json"
import { generateModulramImage } from "../components/Configuradores/imageUtils/modulram"
import { Configurador } from "../components/Configuradores/Configurador"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"

const ConfigModulram = ({ location }) => {
  const { t } = useTranslation()

  const configuradorForm = t("configurador", { returnObjects: true })
  const userForm = t("form", { returnObjects: true })

  const [finalImg, setFinalImg] = useState("")

  const handleFinalImg = inputs => {
    let model = inputs["modelo"],
      traySize = inputs["dimensao-tabuleiro"],
      trayPosition = inputs["posicao-tabuleiros"],
      chambers = inputs["quantidade-camaras"],
      base = inputs["base"],
      pizzasQuantity = inputs["quantidade-pizzas"],
      pizzasPosition = inputs["posicao-pizzas"]

    setFinalImg(generateModulramImage(model, traySize, trayPosition, chambers, base, pizzasQuantity, pizzasPosition))
  }

  return (
    <Layout
      location={location}
      footer={t("footer", { returnObjects: true })}
      header={t("header", { returnObjects: true })}
      hideBr
    >
      <SEO title="Modulram" bodyClass={"configurador"}></SEO>
      <Configurador
        inputsJson={Modulram}
        configuradorForm={configuradorForm}
        userForm={userForm}
        email={configuradorForm["email"]}
        oven={"Modulram"}
        finalImg={finalImg}
        handleFinalImg={handleFinalImg}
      />
    </Layout>
  )
}

export default ConfigModulram

export const ConfiguradorModulram = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["modulram", "footer", "header", "form"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
